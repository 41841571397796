$(function() {
  $('.newsletter-checkbox').on('click', function() {
    enableDisableSubmitBtn();
  })

  $('#mce-LNAME, #mce-EMAIL').on('keyup', function() {
    enableDisableSubmitBtn();
  })

  function enableDisableSubmitBtn () {
    var submitBtn = $('.newsletter .btn');
    var checkboxes = $('.newsletter-checkbox');
    var checkedInputs = $('.newsletter-checkbox:checked');
    var lastName = $('#mce-LNAME').val();
    var email = $('#mce-EMAIL').val();
    var regex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
    var isValidEmail = regex.test(email);

    if (email && !regex.test(email)){
      $('#mce-EMAIL').css({color: 'red'});
      return
    }
    //Change text color if valid email
    $('#mce-EMAIL').css({color: ''});

    if (lastName && isValidEmail && checkboxes.length === checkedInputs.length) {
      submitBtn.removeAttr('disabled');
      submitBtn.removeClass('disabled');
    } else {
      submitBtn.prop('disabled', true);
      submitBtn.addClass('disabled');
    };
  }
})